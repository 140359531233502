.excalidraw {
    --color-primary-contrast-offset: #625ee0;
}
.excalidraw.theme--dark {
    --color-primary-contrast-offset: #726dff;
}
.excalidraw .top-right-ui {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.excalidraw .footer-center {
    justify-content: flex-end;
    margin-top: auto;
    margin-bottom: auto;
    margin-inline-start: auto;
}
.excalidraw .encrypted-icon {
    border-radius: var(--space-factor);
    color: var(--color-primary);
    margin-top: auto;
    margin-bottom: auto;
    margin-inline-start: auto;
    margin-inline-end: 0.6em;
}
.excalidraw .encrypted-icon svg {
    width: 1.2rem;
    height: 1.2rem;
}
.excalidraw .dropdown-menu-container .dropdown-menu-item.active-collab {
    background-color: #ecfdf5;
    color: #064e3c;
}
.excalidraw .dropdown-menu-container .dropdown-menu-item.ExcalidrawPlus {
    color: var(--color-promo);
}
.excalidraw.theme--dark .dropdown-menu-item.active-collab {
    background-color: #064e3c;
    color: #ecfdf5;
}
.excalidraw .collab-offline-warning {
    pointer-events: none;
    position: absolute;
    top: 6.5rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    text-align: center;
    line-height: 1.5;
    border-radius: var(--border-radius-md);
    background-color: var(--color-warning);
    color: var(--color-text-warning);
    z-index: 6;
    white-space: pre;
}
.excalidraw-app.is-collaborating [data-testid="clear-canvas-button"] {
    display: none;
}
.plus-button {
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    border: 1px solid var(--color-primary);
    padding: 0.5rem 0.75rem;
    border-radius: var(--border-radius-lg);
    background-color: var(--island-bg-color);
    color: var(--color-primary) !important;
    text-decoration: none !important;
    font-size: 0.75rem;
    box-sizing: border-box;
    height: var(--lg-button-size);
}
.plus-button:hover {
    background-color: var(--color-primary);
    color: white !important;
}
.plus-button:active {
    background-color: var(--color-primary-darker);
}
.theme--dark .plus-button:hover {
    color: black !important;
}